import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import showdown from 'showdown'

export const FormalPageTemplate = ({ title, page_name, description, image, content }) => {
  const converter = new showdown.Converter();
  const backgroundImage = typeof image === 'string' ? image : image.childImageSharp.fluid.src

   const renderFooterAction = page_name => {
    const pages = {
      home: {
        display: 'Hjem',
        next: '/stiftelsen'
      },
      stiftelsen: {
        display: 'Stiftelsen',
        next: '/styret',
        prev: '/'
      },
      styret: {
        display: 'Formal',
        next: '/formal',
        prev: '/stiftelsen'
      },
      formal: {
        display: 'Formål',
        next: '/fn',
        prev: '/styret'
      },
      fn: {
        display: 'FNs bærekraftsmål',
        next: '/vedteker',
        prev: '/formal'
      },
      vedtekter: {
        display: 'Vedtekter',
        next: '/samarbeidspartnere',
        prev: '/fn'
      },
      samarbeidspartnere: {
        display: 'Samarbeidspartnere',
        prev: '/vedtekter'
      },
    }

    const page = pages[page_name];

    return (
      <div className='footer-action'>
        <Link to={page.prev}  disabled={page.prev == undefined}  className={ `prev ${ page.prev == undefined ? 'disabled' : '' }` } >
          {"<  "}
        </Link>

        <span>{ page.display }</span>

        <Link to={page.next} disabled={page.next == undefined}  className={ `next ${ page.next == undefined ? 'disabled' : '' }` }>
          {"  >"}
        </Link>
      </div>
    )
  }

  return (
  <main id="index" className="foundation" style={{ backgroundImage: `url(${backgroundImage})` }}>
    <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
    </Helmet>
    <Navbar page_name={page_name}/>

    <section className="hero is-fullheight">
      <div className="mt-12">
        <div className="container main-section">
          <div className="columns">
            <div className="column">
            </div>

            <div className="column is-three-quarters">
                <div className="content has-text-white">
                <h1>Formål</h1>

                <h2>
                  Stiftelsen ønsker å støtte gode formål internasjonalt og nasjonalt. Forebygging av barns død <br></br>
                  og trygging av deres oppvekst er særlig prioritet.

                </h2>

                <p>Stiftelsen Signe Marie støtter internasjonale tiltak i henhold til FN ́s bærekraftsmål 1-6. <a href="/fn">Les mer her.</a><br></br>

                <p>Stiftelsen yter også rask støtte til samarbeidspartnerne i akutte situasjoner som krig og ved naturkatastrofer.</p>

                <p>Støtte gis også til teknisk/naturvitenskapelig fagfelt særlig ved Universitetet i Stavanger (UiS) med tanke på forskning / utvikling av helsefremmende infrastruktur i ressursfattige land.</p>
                <p>Stiftelsen skal fremme nyskaping og bedre utnyttelse av forskningsresultater inklusive medisinsk forskning.</p>
                <p>For å ivareta sin målsetning kan SSM opprette og delta i selskaper, stiftelser og andre samarbeidstiltak.</p>
          </p>
              </div>
            </div>

            <div className="column">
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer>
      {renderFooterAction(page_name)}
    </footer>
  </main>
  )
}

FormalPageTemplate.propTypes = {
  image: PropTypes.object.isRequired,
  page_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

const FormalPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <FormalPageTemplate
        page_name={post.frontmatter.page_name}
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        content={post.frontmatter.content}
      />
    </Layout>
  )
}

FormalPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FormalPage

export const FormalPageQuery = graphql`
  query FormalPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        page_name
        title
        description
        content
      }
    }
  }
`
